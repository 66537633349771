import { ReactNode } from "react";
import { Eyebrow as EyebrowComponent } from "src/common/ui/Eyebrow";
import { Heading as HeadingComponent } from "src/common/ui/Heading";
import { Paragraph as ParagraphComponent } from "src/common/ui/Paragraph";
import { Container as ContainerComponent } from "../Container";
import { AnimatedPage } from "../AnimatedPage";

export interface ContentProps {
  children: ReactNode;
  className?: string;
}

function Content({ children, className = "" }: ContentProps) {
  return (
    <ParagraphComponent
      className={`max-w-[auto] text-sm leading-normal md:text-base ${className}`}
      as="div"
    >
      {children}
    </ParagraphComponent>
  );
}

export interface CountProps {
  children: ReactNode;
}

function Count({ children }: CountProps) {
  return (
    <div className="grid h-20 w-20 shrink-0 place-items-center rounded-full bg-emerald-100 2xl:h-24 2xl:w-24">
      <div className="inline-grid place-items-center">
        <HeadingComponent className="text-5xl font-[150] leading-none">
          {children}
        </HeadingComponent>
      </div>
    </div>
  );
}

export interface EyebrowProps {
  children: ReactNode;
}

function Eyebrow({ children }: EyebrowProps) {
  return (
    <EyebrowComponent as="div" className="text-xs text-muted xl:text-sm">
      {children}
    </EyebrowComponent>
  );
}

export interface HeaderProps {
  children: ReactNode;
}

function Header({ children }: HeaderProps) {
  return (
    <div className="grid gap-y-4 pb-5 md:py-10 lg:py-12 xl:gap-y-6 xl:py-10">
      {children}
    </div>
  );
}

export interface HeadingProps {
  children: ReactNode;
}

function Heading({ children }: HeadingProps) {
  return (
    <HeadingComponent
      display
      as="h1"
      className="max-w-[500px] pr-[10%] text-2xl tracking-[-0.03em] text-accent md:text-3xl xl:text-3xl xl:leading-tight xl:tracking-[-0.03em]"
    >
      {children}
    </HeadingComponent>
  );
}

export interface IconProps {
  children: ReactNode;
  className?: string;
}

export interface MainProps {
  children: ReactNode;
  animated?: boolean;
  className?: string;
}

function Icon({ children, className }: IconProps) {
  return (
    <div
      className={`grid h-20 w-20 shrink-0 place-items-center rounded-full bg-emerald-100 p-4 2xl:h-24 2xl:w-24 ${
        className ?? ""
      }`}
    >
      <div className="inline-grid h-10 w-10 place-items-center [&>*]:max-h-full [&>*]:max-w-full">
        {children}
      </div>
    </div>
  );
}

function Main({ children, animated, className = "" }: MainProps) {
  if (animated) {
    return (
      <AnimatedPage>
        <main className={`pb-16 xl:pb-32 ${className}`}>{children}</main>
      </AnimatedPage>
    );
  } else {
    return <main className={`pb-16 xl:pb-32 ${className}`}>{children}</main>;
  }
}

export interface PageProps {
  children: ReactNode;
  className?: string;
}

export function Page({ children, className = "" }: PageProps) {
  return (
    <ContainerComponent
      className={`xl:pt-18 px-6 pb-0 pt-14 lg:pb-16 lg:pt-14 xl:pb-0 ${className}`}
    >
      {children}
    </ContainerComponent>
  );
}

function Sections({ children }: { children: ReactNode }) {
  return <div className="grid gap-8 xl:gap-12 2xl:gap-16">{children}</div>;
}

export interface TitleProps {
  children: ReactNode;
  display?: boolean;
  className?: string;
  includeResponsiveness?: boolean;
}

function Title({
  children,
  display = true,
  className = "",
  includeResponsiveness = true,
}: TitleProps) {
  function getResponsiveClasses() {
    if (includeResponsiveness)
      return "md:text-2xl xl:text-3xl xl:leading-tight";
    return "";
  }

  return (
    <HeadingComponent
      display={display}
      as="h2"
      className={`max-w-[auto] text-2xl tracking-[-0.03em] text-accent ${getResponsiveClasses()} ${className}`}
    >
      {children}
    </HeadingComponent>
  );
}

export interface ParagraphProps {
  children: ReactNode;
}

function Paragraph({ children }: ParagraphProps) {
  return (
    <ParagraphComponent className="max-w-prose pr-[10%] leading-tight md:text-lg">
      {children}
    </ParagraphComponent>
  );
}

Page.Content = Content;
Page.Count = Count;
Page.Eyebrow = Eyebrow;
Page.Header = Header;
Page.Heading = Heading;
Page.Icon = Icon;
Page.Main = Main;
Page.Paragraph = Paragraph;
Page.Sections = Sections;
Page.Title = Title;
