import { ReactNode } from "react";
import { Grid } from "src/common/ui/Grid";

export interface CenteredContainerProps {
  children: ReactNode;
  className?: string;
  useGrid?: boolean;
}

export function CenteredContainer({
  children,
  className,
  useGrid = true,
}: CenteredContainerProps) {
  const margins =
    className && className?.indexOf("ml-") > -1 ? "" : "ml-auto mr-auto";
  return (
    <>
      {useGrid && (
        <Grid>
          <CenteredContainer.Center
            className={`col-span-12 md:col-[2/12] lg:col-[3/11] xl:col-[3/11] 2xl:col-[4/10] 3xl:col-[5/9] ${
              className ?? "px-5"
            }`}
          >
            {children}
          </CenteredContainer.Center>
        </Grid>
      )}
      {!useGrid && (
        <CenteredContainer.Center
          className={`w-full max-w-[1440px] ${margins} md:px-10 ${
            className ?? "px-5"
          }`}
        >
          {children}
        </CenteredContainer.Center>
      )}
    </>
  );
}

function Center({
  children,
  className = "col-span-12 md:col-[2/12] lg:col-[3/11] xl:col-[3/11] 2xl:col-[4/10] 3xl:col-[5/9]",
}: {
  children: ReactNode;
  className?: string;
}) {
  return <div className={`${className}`}>{children}</div>;
}

function Right({ children }: { children: ReactNode }) {
  return <div className="2xl:col-[10/13]">{children}</div>;
}

CenteredContainer.Center = Center;
CenteredContainer.Grid = Grid;
CenteredContainer.Right = Right;
