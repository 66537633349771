import { yupResolver } from "@hookform/resolvers/yup";
import { AsYouType, parsePhoneNumber } from "libphonenumber-js";
import { ChangeEvent, Dispatch, SetStateAction, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { sendMobileConfirmationCode } from "src/api/clients/loginClient";
import { Button } from "src/common/components/Button";
import { PHONE_NUMBER_SCHEMA } from "src/common/config/form-validation";
import { InputFieldset } from "src/common/ui/InputFieldset";
import {
  InputFieldLabel,
  InputFieldPlaceholder,
} from "src/employee/onboarding-v2/components/types";
import { InputFieldType } from "src/employee/onboarding/components/EditConfigContext";
import * as yup from "yup";

const schema = yup
  .object({
    phoneNumber: PHONE_NUMBER_SCHEMA,
  })
  .required();

type FormData = yup.InferType<typeof schema>;

export interface MobilePhoneFormProps {
  isDisabled: boolean;
  phoneNumber: string | null;
  setPhoneNumber: Dispatch<SetStateAction<string | null>>;
}

export function MobilePhoneForm({
  isDisabled,
  phoneNumber,
  setPhoneNumber,
}: MobilePhoneFormProps) {
  const [serverError, setServerError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    watch,
  } = useForm<FormData>({
    defaultValues: {
      phoneNumber: "",
    },
    resolver: yupResolver(schema),
  });

  const onSubmit: SubmitHandler<FormData> = async ({ phoneNumber }) => {
    if (!isValid) return;

    setIsLoading(true);
    setServerError(null);

    try {
      const parsedPhoneNumber = parsePhoneNumber(phoneNumber, "US").number;
      const result = await sendMobileConfirmationCode(parsedPhoneNumber);
      // we only want to show "We are unable to send a login code..." error to user
      if (
        result?.error &&
        result.error[0].indexOf("We are unable to send a login code") != -1
      ) {
        setServerError(result.error[0] ?? null);
      } else {
        setPhoneNumber(parsedPhoneNumber);
      }
    } finally {
      setIsLoading(false);
    }
  };

  function formatPhone(event: ChangeEvent<HTMLInputElement>) {
    setServerError(null);

    const formatted = new AsYouType("US").input(event.target.value);
    setValue("phoneNumber", formatted);
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <InputFieldset
        {...register("phoneNumber", {
          onChange: formatPhone,
        })}
        autoFocus={true}
        error={errors.phoneNumber?.message ?? serverError ?? undefined}
        isDisabled={isDisabled}
        label={InputFieldLabel.MOBILE_PHONE_NUMBER}
        maxLength={16}
        placeholder={InputFieldPlaceholder.PHONE_NUMBER}
        type={InputFieldType.TEL}
        value={watch("phoneNumber")}
      />
      {!phoneNumber && (
        <div className="mt-8">
          <Button
            isLoading={isLoading}
            size="lg"
            type="submit"
            rounded
            variant="primary"
          >
            Text login code
          </Button>
        </div>
      )}
    </form>
  );
}
