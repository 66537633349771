"use client";

import { usePathname } from "next/navigation";
import { getFormFlowClient } from "src/api/resources/factory";
import { CLIENT_ENDPOINTS } from "src/common/config/client-endpoints";
import { formatErrorMessage } from "src/common/utils/format-error-message";
import { getBackendBaseUrl } from "src/common/utils/get-backend-base-url";
import { OnboardingType } from "src/employee/onboarding-v2/components/types";
import { FormFlow } from "src/employee/onboarding/types/form-flow";
import { FlowKey } from "src/employee/onboarding/components/FlowContent";
import { EMPTY_EMPLOYEE_FORM_FLOW } from "src/employee/onboarding/config/employee-form-flow";
import useGlobalCache from "src/common/hooks/use-global-cache";
import useSWR from "swr";

const API_URL: string = `${getBackendBaseUrl()}${CLIENT_ENDPOINTS.FORM_FLOW}`;

export interface UseEmployeeFormFlowArgs {
  shouldFetch: boolean;
  employerId?: string;
  pbm?: string;
}

function useEmployeeFormFlow(
  { shouldFetch, employerId, pbm }: UseEmployeeFormFlowArgs = {
    shouldFetch: true,
  },
) {
  const formFlowClient = getFormFlowClient(
    OnboardingType.MEMBER,
    employerId,
    pbm,
  );
  const pathname = usePathname() ?? "";
  const { getItem } = useGlobalCache();

  async function getFormFlow() {
    const { error, data } = await formFlowClient.getFormFlow(pathname);
    if (error) {
      console.error(error);
      throw new Error(formatErrorMessage(error));
    }

    return data;
  }

  async function updateFormFlow(
    page: FlowKey,
    action: string,
    payload: object,
  ): Promise<FormFlow> {
    const { data, error } = await formFlowClient.updateFormFlow(page, action, {
      ...(getItem(API_URL) || EMPTY_EMPLOYEE_FORM_FLOW),
      ...payload,
    });

    if (error) {
      console.error(error);
      throw new Error(formatErrorMessage(error));
    }

    return data;
  }

  const { data, isLoading, error, mutate } = useSWR<FormFlow, Error>(
    shouldFetch ? API_URL : null,
    getFormFlow,
  );

  return {
    data: data || EMPTY_EMPLOYEE_FORM_FLOW,
    error,
    isLoading,
    mutate,
    updateFormFlow,
  };
}

export default useEmployeeFormFlow;
