import { CSSProperties, ReactNode } from "react";

export interface GridProps {
  className?: string;
  children: ReactNode;
  style?: CSSProperties;
}

export function Grid({ children, className, style }: GridProps) {
  return (
    <div
      className={`grid grid-flow-dense grid-cols-12 gap-x-5 gap-y-5 lg:gap-x-7 ${
        className ?? ""
      }`}
      style={style}
    >
      {children}
    </div>
  );
}
