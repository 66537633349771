"use client";
import { ReactNode, useEffect, useState } from "react";
import { CSSTransition } from "react-transition-group";

export function AnimatedPage({ children }: { children: ReactNode }) {
  const [animateIn, setAnimateIn] = useState(false);
  useEffect(() => {
    setAnimateIn(true);
    window.scrollTo(0, 0);
  }, [animateIn]);

  return (
    <CSSTransition classNames="page-animation" in={animateIn} timeout={1000}>
      {children}
    </CSSTransition>
  );
}
