"use client";

import { yupResolver } from "@hookform/resolvers/yup";
import { Dispatch, SetStateAction, useState } from "react";
import { useForm } from "react-hook-form";
import {
  sendMobileConfirmationCode,
  validateConfirmationCode,
} from "src/api/clients/loginClient";
import { Button } from "src/common/components/Button";
import { CONFIRMATION_CODE_SCHEMA } from "src/common/config/form-validation";
import { Heading } from "src/common/ui/Heading";
import { InlineMessage, InlineMessageType } from "src/common/ui/InlineMessage";
import { InputFieldset } from "src/common/ui/InputFieldset";
import { Paragraph } from "src/common/ui/Paragraph";
import { formatErrorMessage } from "src/common/utils/format-error-message";
import {
  EditConfigTitle,
  InputFieldPlaceholder,
} from "src/employee/onboarding-v2/components/types";
import { InputFieldType } from "src/employee/onboarding/components/EditConfigContext";
import * as yup from "yup";

const schema = yup
  .object({
    confirmationCode: CONFIRMATION_CODE_SCHEMA,
  })
  .required();

type FormData = yup.InferType<typeof schema>;

export interface ConfirmMobilePhoneFormProps {
  onConfirm: () => void;
  phoneNumber: string;
  setIsMobilePhoneDisabled: Dispatch<SetStateAction<boolean>>;
}

export function ConfirmMobilePhoneForm({
  onConfirm,
  phoneNumber,
  setIsMobilePhoneDisabled,
}: ConfirmMobilePhoneFormProps) {
  const [isLoadingLogin, setIsLoadingLogin] = useState(false);
  const [isLoadingResend, setIsLoadingResend] = useState(false);
  const [submitServerError, setSubmitServerError] = useState<string | null>(
    null,
  );
  const [resendServerError, setResendServerError] = useState<string | null>(
    null,
  );
  const [resendBackendSuccess, setResendBackendSuccess] = useState<
    string | null
  >(null);

  const {
    clearErrors,
    formState: { errors, isValid },
    handleSubmit,
    register,
    trigger,
    watch,
  } = useForm<FormData>({
    defaultValues: {
      confirmationCode: "",
    },
    resolver: yupResolver(schema),
  });

  async function login() {
    await trigger();

    if (!isValid) return;

    setIsLoadingLogin(true);
    setIsMobilePhoneDisabled(true);
    setResendBackendSuccess(null);
    setResendServerError(null);
    setSubmitServerError(null);

    try {
      const confirmationCode = watch("confirmationCode");

      const response = await validateConfirmationCode({
        mobile: phoneNumber,
        token: confirmationCode,
      });

      if (!response.success) {
        const error = formatErrorMessage(response.error);
        throw error;
      }

      onConfirm();
    } catch (error) {
      console.error(error);
      const errorMessage = formatErrorMessage(error);
      setSubmitServerError(errorMessage);
    } finally {
      clearErrors();
      setIsLoadingLogin(false);
      setIsMobilePhoneDisabled(false);
    }
  }

  async function resendTextMessage() {
    setIsLoadingResend(true);
    setIsMobilePhoneDisabled(true);
    setResendBackendSuccess(null);
    setResendServerError(null);
    setSubmitServerError(null);

    try {
      await sendMobileConfirmationCode(phoneNumber);

      // Note:
      // even if fails, display following message
      //
      // backend returns "Unable to send you a login code. Try again later." in
      // case the user doesn't exist. Should instead return success all the time, but print
      // error in backend logs?
      setResendBackendSuccess(
        "Message sent. You will receive it within a few seconds.",
      );
    } catch (error) {
      const errorMessage = formatErrorMessage(error);
      setResendServerError(errorMessage);
    } finally {
      setIsLoadingResend(false);
      setIsMobilePhoneDisabled(false);
    }
  }

  return (
    <form onSubmit={handleSubmit(login)}>
      <div className="mb-4">
        <Heading className="mb-1 text-lg">Check your phone</Heading>
        <Paragraph>
          If this account exists, we&apos;ve sent you six-digit confirmation
          code for you to enter.
        </Paragraph>
      </div>

      <div className="grid gap-y-8 xl:gap-y-10">
        <div className="grid gap-3">
          <InputFieldset
            {...register("confirmationCode")}
            autoFocus={true}
            error={
              errors?.confirmationCode?.message ??
              submitServerError ??
              undefined
            }
            isDisabled={isLoadingLogin ?? isLoadingResend}
            label={EditConfigTitle.CONFIRMATION_CODE}
            maxLength={6}
            placeholder={InputFieldPlaceholder.CONFIRMATION_CODE}
            type={InputFieldType.NUMBER}
            value={watch("confirmationCode")}
          />
        </div>
        <div>
          <div className="flex flex-col items-start gap-4">
            <div className="flex flex-col items-stretch gap-4">
              <Button
                isDisabled={isLoadingResend}
                isLoading={isLoadingLogin}
                onClick={login}
                size="lg"
                type="button"
                variant="primary"
              >
                Log in
              </Button>
              <Button
                isDisabled={isLoadingLogin}
                isLoading={isLoadingResend}
                onClick={resendTextMessage}
                size="lg"
                type="button"
                variant="transparent"
              >
                Resend code
              </Button>
            </div>
            {!!resendBackendSuccess && (
              <InlineMessage type={InlineMessageType.SUCCESS}>
                {resendBackendSuccess}
              </InlineMessage>
            )}
            {!!resendServerError && (
              <InlineMessage type={InlineMessageType.ERROR}>
                {resendServerError}
              </InlineMessage>
            )}
          </div>
        </div>
      </div>
    </form>
  );
}
