"use client";

import Link from "next/link";
import { useEffect, useState } from "react";
import { CenteredContainer } from "src/common/components/CenteredContainer";
import { ROUTES } from "src/common/config/routes";
import { Heading } from "src/common/ui/Heading";
import { TextLink } from "src/common/ui/TextLink";
import { Page } from "src/employee/onboarding/components/Page";
import { ConfirmMobilePhoneForm } from "../ConfirmMobilePhoneForm";
import { MobilePhoneForm } from "../MobilePhoneForm";
import { useRouter, useSearchParams } from "next/navigation";
import useEmployeeFormFlow from "src/common/hooks/apiCalls/employeeFormFlow/useFormFlowApp";
import { FormFlow } from "src/employee/onboarding/types/form-flow";
import { isValidToken } from "src/api/clients/loginClient";
import { getIsUserActivated } from "src/common/utils/get-is-user-activated";

export function Login() {
  const [phoneNumber, setPhoneNumber] = useState<string | null>(null);
  const [isConfirmed, setIsConfirmed] = useState<boolean>(false);
  const [isGoingToNextPage, setIsGoingToNextPage] = useState<boolean>(false);
  const [isMobilePhoneDisabled, setIsMobilePhoneDisabled] = useState(false);
  const hasValidToken = isValidToken();
  const { push } = useRouter();
  const { mutate: refetchFormFlow } = useEmployeeFormFlow({
    shouldFetch: true,
  });
  const params = useSearchParams();

  useEffect(() => {
    if (isGoingToNextPage) return;

    async function goToNextPage() {
      setIsGoingToNextPage(true);
      const formFlow = await refetchFormFlow<FormFlow>();
      const [isAuthenticated, isActivated] = await getIsUserActivated();

      if (isActivated) {
        const redirectPage = params?.get("next") || ROUTES.EMPLOYEE.PORTAL.ROOT;
        push(redirectPage);
      } else if (formFlow?.lastPage) {
        push(`/signup/${formFlow.lastPage}`);
      } else {
        push(ROUTES.EMPLOYEE.ONBOARDING.ACTIVATE_YOUR_ACCOUNT);
      }
    }

    if (isConfirmed || hasValidToken) goToNextPage();
  }, [
    isConfirmed,
    isGoingToNextPage,
    push,
    refetchFormFlow,
    hasValidToken,
    params,
  ]);

  return (
    <Page>
      <Page.Main animated>
        <CenteredContainer className="px-0">
          <Page.Header>
            <Page.Title>Welcome back</Page.Title>
            <Page.Content>Let&apos;s get you logged in.</Page.Content>
          </Page.Header>
          <Page.Sections>
            <MobilePhoneForm
              isDisabled={!!phoneNumber ?? isMobilePhoneDisabled}
              phoneNumber={phoneNumber}
              setPhoneNumber={setPhoneNumber}
            />
            {!!phoneNumber && (
              <ConfirmMobilePhoneForm
                onConfirm={() => setIsConfirmed(true)}
                phoneNumber={phoneNumber}
                setIsMobilePhoneDisabled={setIsMobilePhoneDisabled}
              />
            )}
            <Page.Content>
              <Heading className="mb-1 font-normal">
                Didn&apos;t activate your account yet?
              </Heading>
              <TextLink
                as={Link}
                href={ROUTES.EMPLOYEE.ONBOARDING.ACTIVATE_YOUR_ACCOUNT}
                variant="underline"
              >
                Activate it here.
              </TextLink>
            </Page.Content>
          </Page.Sections>
        </CenteredContainer>
      </Page.Main>
    </Page>
  );
}
